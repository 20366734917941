import React from "react"

import { Link } from "gatsby"
import Layout from "../layouts/indexLayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>You look lost...</h1>
    <p>
      This page does not exist. Try the <Link to="/">home page</Link> instead.
    </p>
  </Layout>
)

export default NotFoundPage
